import React from "react";
import { Navbar } from "reactstrap";
import classnames from "classnames";
import NavbarUser from "./NavbarUser";
import { useStore, useSelector } from "react-redux";

const ThemeNavbar = (props) => {
  const colorsArr = ["primary", "danger", "success", "info", "warning", "dark"];
  const navbarTypes = ["floating", "static", "sticky", "hidden"];
  const username = useSelector((state) => state.auth.login.username);
  const title = useSelector((state) => state.navbar.title);
  return (
    <React.Fragment>
    {typeof username !== "undefined" ? 
      <div>
        <div className="content-overlay" />
        <Navbar
          className={classnames(
            "header-navbar navbar-expand-lg navbar navbar-with-menu navbar-shadow",
            {
              "navbar-static-top":
                props.navbarType === "static" && !props.horizontal,
              "fixed-top": props.navbarType === "sticky" || props.horizontal,
              scrolling: props.horizontal && props.scrolling,
            }
          )}
        >
          <div className="navbar-wrapper">
            <div className="navbar-container content">
              <div
                className="navbar-collapse d-flex justify-content-between align-items-center"
                id="navbar-mobile"
              >
                <NavbarUser
                  handleAppOverlay={props.handleAppOverlay}
                  changeCurrentLang={props.changeCurrentLang}
                  userName={username}
                  title={title}
                />
              </div>
            </div>
          </div>
        </Navbar>
        </div>
     :
     null
     }
    </React.Fragment>
  );
};

export default ThemeNavbar;
