import { createSlice } from "@reduxjs/toolkit";
import { AXIOS_INSTANCE, ROUTES, URL_BACKEND } from "../../../configs/routing";
import { history } from "../../../history";
import { asyncHelperActions } from "../ayncHelper/asyncHelperSlice";
import { COMPONENTS } from "../../../configs/components";
import { batch } from "react-redux";
import { LOCAL_STORAGE } from "../../../configs/localstorage";
import { MyAccountSlice } from "../myAccount/slice";
import myAccount from "../myAccount";

const slice = createSlice({
  name: "auth",
  initialState: {
    token: null,
    username: null,
    userUID: null,  
  },
  reducers: {
    setUserDetails: (state, action) => {
      state.username = action.payload.username;
      state.token = action.payload.token;
      state.userUID = action.payload.userUID;    
    },
    clearUserDetails: (state) => {
      state.username = null;
      state.token = null;
      state.userUID = null;    
    },
  },
});

export const handleLogin = ({
  email,
  password,
  remember /*@TODO remember user*/,
}) => {
  return (dispatch) => {
    dispatch(
      asyncHelperActions.setLoading({ componentName: COMPONENTS.LOGIN })
      );
      return AXIOS_INSTANCE.post(URL_BACKEND + ROUTES.LOGIN, {
        email: email,
        password:password,
      })
      .then((response) => {
        const { data } = response;
        const newUserDetails = {
          token: data.token,
          userID: data.user.id,
          userUID: data.user.uid,
          username: data.user.email,
        };
        localStorage.setItem(
          LOCAL_STORAGE.AUTH_STORE,
          JSON.stringify(newUserDetails)
        );

        const newAccountDetails = data.user; //myAccount
        localStorage.setItem(
          LOCAL_STORAGE.MY_ACCOUNT,
          JSON.stringify(newAccountDetails)
        );


        batch(() => {
          dispatch(slice.actions.setUserDetails(newUserDetails)); //auth

          dispatch(
            //myAccount
            MyAccountSlice.actions.setAccount({ account: newAccountDetails })
          );

          dispatch(
            asyncHelperActions.setSuccess({ componentName: COMPONENTS.LOGIN })
          );
        });
      })
      .catch((e) => {
        if (e.message === "Network Error") {
          e.message = "The server could not be reached";
        } else if (e.response.status === 401) {
          e.message = "Invalid username or password. Please try again";
        }

        dispatch(
          asyncHelperActions.setError({
            componentName: COMPONENTS.LOGIN,
            message: e.message,
          })
        );
        throw e;
      });
  };
};

export const handleLogout = () => {
  return (dispatch) => {
    dispatch(slice.actions.clearUserDetails());
    dispatch(MyAccountSlice.actions.clearAccount());

    dispatch(myAccount.clearAccount());
    localStorage.removeItem("guide_education_auth");
    history.push("/login");
  };
};

export const login = slice.reducer;
