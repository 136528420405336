import Axios from "axios";

export const BACKEND = process.env.REACT_APP_BACKEND;
export const BACKEND_PORT = process.env.REACT_APP_BACKEND_PORT;
export const BACKEND_WS = process.env.REACT_APP_BACKEND_WS;
export const URL_BACKEND = BACKEND + ":" + BACKEND_PORT + "/";
export const URL_BACKEND_WS = BACKEND_WS + ":" + BACKEND_PORT + "/";

export const ROUTES = {
  GRAPH: "g",
  GUID: "guid",
  NODE: "node",
  LINK: "link",
  LOGIN: "login",
  METADATA_SIMPLE: "metadata_simple",
  METADATA: "metadata",
  PUBLIC: "public",
  LIST: "list",
  FAVOURITES: "/favorites",
  ORGANIZATIONS: "organizations",
  ORDER_METADATA: "metadata_order",
  ORGANIZATIONS: "organizations",
};

export const AXIOS_INSTANCE = Axios.create({
  baseURL: URL_BACKEND,
});
