import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import createDebounce from "redux-debounced";
import thunk from "redux-thunk";
import authReducers from "../slices/auth";
import navbarReducer from "../slices/navbar/Index";
import { modalReducer } from "../slices/modal/modalReducer";
import userReducers from "../slices/user";
import { asyncHelperReducer } from "../slices/ayncHelper/asyncHelperSlice";
import myAccount from "../slices/myAccount";
import { customizerReducer } from "../slices/customizer/customizer";
import { LOCAL_STORAGE } from "../../configs/localstorage";

const middlewares = [...getDefaultMiddleware(), thunk, createDebounce()];

const store = configureStore({
  reducer: {
    asyncHelper: asyncHelperReducer,
    myAccount: myAccount.myAccountReducer,
    customizer: customizerReducer,
    auth: authReducers,
    navbar: navbarReducer,
    userApp: userReducers,
    modal: modalReducer,
  },
  middleware: middlewares,
  preloadedState: {
    auth: {
      login: {
        ...JSON.parse(localStorage.getItem(LOCAL_STORAGE.AUTH_STORE)),
      },
    },
    myAccount: {
      account: {
        ...JSON.parse(localStorage.getItem(LOCAL_STORAGE.MY_ACCOUNT)),
      }
    },
  },
});

export { store };
